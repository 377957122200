const CDN_URL = process.env.REACT_APP_CDN_URL;
const CDN_ROOT = process.env.REACT_APP_CDN_ROOT;
const PAGE = process.env.REACT_APP_PAGE;
const API_URL = process.env.REACT_APP_API_URL;
const DEBUG = process.env.REACT_APP_DEBUG=='1';
const META_PIXEL = process.env.REACT_APP_PIXEL;
const GOOGLE_TAG = process.env.REACT_APP_GOOGLE_TAG;
const PLACEHOLDER_IMG = `${window.location.origin}/placeholder_portrait.webp`;

export {
	API_URL,
	CDN_ROOT,
	CDN_URL,
	DEBUG,
	GOOGLE_TAG,
	META_PIXEL,
	PAGE,
	PLACEHOLDER_IMG,
}