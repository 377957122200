import React, { useEffect, useState } from 'react';
import { Image } from 'react-frontier';

import './style/product_image.scss';

interface ProductImageProps{
	image: string,
	width?: number,
	fallback?: string,
}

var ProductImage = (props: ProductImageProps)=>{
	return <div className="co product-image" style={{
		width: props.width,
	}}>
		<div className="image">
			<Image src={props.image} fallback={props.fallback || '/placeholder_portrait.webp'} />
		</div>
	</div>
}

export default ProductImage;