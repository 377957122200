import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { CDN_URL, PLACEHOLDER_IMG } from '../ConfigPublic';
import { Button, Header, Image, Input } from 'react-frontier';
import { TransitionGroup, Dropdown, Icon } from 'semantic-ui-react'
import { useCart, useWebdata } from '../HooksPublic';
import { addCommas } from '../UtilPublic';
import { CartItem, SimpleProgress } from '@cocoan/components';
import classNames from 'classnames';

interface NavbarItem{
	text: string,
	href?: string,
	items?: {
		text: string,
		href: string,
	}[]
}

const NAVBAR_ITEMS : NavbarItem[] = [
	{ text: 'Inicio', href: '' },
	{ text: 'Comprar', href: 'shop' },
	{ text: 'Visitanos', href: 'visitanos' },
	{ text: 'Ordenes', href: 'ordenes' },
	{ text: 'Personalizados', href: 'productospersonalizados' },
	{ text: 'Mas', items: [,
		{ text: 'Facturación', href: '/facturar' },
		{ text: 'Ayuda + FAQ', href: '/ayuda' },
		{ text: 'Términos', href: '/terminos' },
	]},
]

var Navbar = ()=>{
	var { pathname } = useLocation();
	const IS_CHECKOUT = pathname.startsWith('/checkout');
	var { cart, items, updateCart, hideCart, showCart, showing, total: cartTotal } = useCart();
	var [sidemenu, setSidemenu] = useState<boolean>(false);
	var [searchBar, setSearchBar] = useState<boolean>(false);
	var [searchValue, setSearchValue] = useState<string>('');
	var webdata = useWebdata();
	var searchRef = useRef<HTMLInputElement>(null);
	var navigate = useNavigate();
	
	var toggleCart = ()=>{
		if(showing) hideCart();
		else showCart()
		setSidemenu(false);
		setSearchBar(false);
	}

	var toggleSidemenu = ()=>{
		setSidemenu(!sidemenu);
		setSearchBar(false);
		hideCart();
	}

	var toggleSearchBar = ()=>{
		setSearchBar(!searchBar);
		hideCart();
		setSidemenu(false);
		setTimeout(()=>{
			searchRef.current?.focus();
		}, 320);
	}

	var hideAll = ()=>{
		hideCart()
		setSidemenu(false);
		setSearchBar(false);
	}

	var searchProducts = ()=>{
		navigate(`/search?q=${encodeURIComponent(searchValue)}`);
		hideAll();
	}

	var updateCartQuantity = (product_id: number)=>{
		return (v: { target: { value: any } })=>{
			updateCart(product_id, parseInt(v.target.value))
		}
	}

	if(IS_CHECKOUT){
		return <div className="co navbar">
			<div className="left">
				<Link className="item" to="/shop">
					<i className="arrow left icon"></i> Regresar
				</Link>
			</div>
			<Link className="logo" to="/">
				<img src={`${CDN_URL}/static/logo/Logo_Black_Tiny.png`} alt="COCOAN" />
			</Link>
			<div className="right"></div>
		</div>
	}

	return <>
		<div className="co navbar">
			<div className="menu">
				<div className="item" onClick={toggleSidemenu}>
					<i className="hamburger icon"></i>
				</div>
			</div>
			<div className="left">
				{NAVBAR_ITEMS.map((a, i)=>(
					a.items ? (
						<Dropdown item text={a.text} className='desktop' key={`navbar-itm-${i}`}>
							<Dropdown.Menu>
								{a.items.map((b, i) => (
									<Dropdown.Item key={`dropdown-itm-${i}`} as={Link} to={b.href}>
										{b.text}
									</Dropdown.Item>
								))}
							</Dropdown.Menu>
						</Dropdown>
					) : (
						<Link className="item desktop" to={`/${a.href}`} key={`navbar-itm-${i}`}>{a.text}</Link>
					)
				))}
			</div>
			<Link className="logo" to="/">
				<img src={`${CDN_URL}/static/logo/Logo_Black_Tiny.png`} alt="COCOAN" />
			</Link>
			<div className="right">
				<div className="item" onClick={toggleCart}>
					<i className="bag shopping icon"></i>
					{items>0 && (
						<div className="tag">{items}</div>
					)}
				</div>
				<div className="item" onClick={toggleSearchBar}>
					<i className="search icon"></i>
				</div>
			</div>
		</div>
		<TransitionGroup animation={'fade'} duration={200}>
			{(showing || sidemenu || searchBar) && (
				<div className={classNames('co dimmer', {
					nav: sidemenu
				})} onClick={hideAll}></div>
			)}
		</TransitionGroup>
		<TransitionGroup animation={'fade down'} duration={300}>
			{searchBar && (
				<div className={'co searchbar'}>
					<div className="contents">
						<Header text='Busca en COCOAN' />
						<Input ref={searchRef} value={searchValue} onChange={setSearchValue} placeholder='Busca chocolates, tabletas, fresas, tus favoritos...' onSubmit={searchProducts} submitOnEnter />
						<div className={classNames('comment', {
							hidden: searchValue.length<3
						})}>Presiona enter para realizar la busqueda</div>
					</div>
					<div className="close" onClick={hideAll}>
						<i className="remove icon"></i>
					</div>
				</div>
			)}
		</TransitionGroup>
		<TransitionGroup animation={'fade right'} duration={300}>
			{sidemenu && (
				<div className={'co sidemenu'}>
					{NAVBAR_ITEMS.map((a,i)=>(
						!a.items ? (
							<Link className='item' key={`sidebar-item-${i}`} to={a.href} onClick={toggleSidemenu}>{a.text}</Link>
						) : (
							a.items.map((b, j)=>(
								<Link className='item' key={`sidebar-item-${i}-${j}`} to={b.href} onClick={toggleSidemenu}>{b.text}</Link>
							))
						)
					))}
				</div>
			)}
		</TransitionGroup>
		<TransitionGroup animation={'fade left'} duration={300}>
			{showing && (
				<div className={classNames('co cart', {
					empty: !cart || cart.length==0
				})}>
					<div className="head">
						<div className="text">Carrito</div>
						<div className="close">
							<i className="remove icon" onClick={toggleCart}></i>
						</div>
					</div>
					{!cart || cart.length==0 ? (
						<Header text='Tu carrito está vacío' size='small' iconName='frown' style={{ marginTop: 20 }} actions={(
							<Button text="Cerrar" color='black' onClick={toggleCart} style={{ fontSize: 16, marginTop: 10 }} />
						)} />
					) : <>
						<div className="items">
							{!!webdata && (
								<div className="progress">
									<div className="header">
										{cartTotal<webdata.variables.shipping_limit ? <>
											¡Te faltan <div className="label">${addCommas(webdata.variables.shipping_limit-cartTotal)}</div> para que tu envío sea {webdata.variables.shipping_cost<=0 ? 'gratis' : `de $${addCommas(webdata.variables.shipping_cost, false, true)}`}!
										</> : <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
											<Icon name='check circle' style={{ fontSize: 20 }} />
											<div style={{ lineHeight: '24px' }}>
												¡Tu pedido califica para envío {webdata.variables.shipping_cost<=0 ? 'gratis' : `de $${addCommas(webdata.variables.shipping_cost, false, true)}`}!
											</div>
										</div>}
									</div>
									<SimpleProgress value={cartTotal} max={webdata.variables.shipping_limit} style={{ marginTop: 5 }} />
								</div>
							)}
							{cart.map(a=>{
								var granel = a.price_gram && a.price_gram>0;
								return <CartItem 
									product={a} 
									amount={a.quantity} 
									image={`${CDN_URL}/products/${a.product_id}/0.webp`} 
									placeholderImage={PLACEHOLDER_IMG}
									actions={<>
										<div className="quantity">
											<select onChange={updateCartQuantity(a.product_id)} value={a.quantity}>
												<option value={-1}>Eliminar</option>
												{new Array(10).fill(0).map((_, i)=>(
													<option value={i+1} key={`selopt-${a.product_id}-${i}`}>
														{(i+1)*(granel ? 100 : 1)}
														{!!granel && ' gr'}
													</option>
												))}
											</select>
											<div className="label">Cantidad: </div>
											{granel ? (
												`${a.quantity*100}gr`
											) : (
												`x${a.quantity}`
											)}
											<i className="chevron down icon"></i>
										</div>
										<Button icon iconName='trash' color='black' basic className='remove' onClick={()=>updateCartQuantity(a.product_id)({ target: { value: 0 } })} />
									</>}
								/>
							})}
						</div>
						<div className="totals">
							<div className="divider" style={{ marginBottom: 10 }}></div>
							<table className="fr table basic">
								<tbody>
									<tr>
										<td>Productos</td>
										<td className="collapsing">${addCommas(cartTotal, true, true)}</td>
									</tr>
									<tr className='total'>
										<td>Subtotal</td>
										<td className="collapsing">${addCommas(cartTotal, true, true)}</td>
									</tr>
								</tbody>
							</table>
							<div className="divider"></div>
							<Button size="big" fluid color="black" text="Completar compra" as={Link} to="/checkout" onClick={toggleCart} />
						</div>
					</>}
				</div>
			)}
		</TransitionGroup>
	</>
}

export default Navbar;