import React, { useEffect, useImperativeHandle, useState } from 'react';
import { Field, Input, Message } from 'react-frontier';
import { bindFormChange } from '../UtilPublic';
import { ProcessorProvider } from '@cocoan/components/Classes';
import { useWebdata } from '../HooksPublic';
import { usePayfront } from 'react-payfront';
import Validator from '@cocoan/components/Validator';

export interface PayfrontFormElement{
	getToken: (amount: number)=>Promise<TokenResponse>
}

interface PayfrontFormProps{
	sandbox: boolean,
	orderEmail?: string,
	payfront: {
		clientId: string,
		publicKey: string,
	}
	openpay?: {
		merchantId: string,
		apiKey: string
	}
}

interface PayfrontError{
	message_client: string
	message: string,
}

interface TokenResponse{
	error: boolean,
	message?: string,
	message_internal?: string,
	data?: {
		type: ProcessorProvider,
		device?: string,
		token: string,
		digits: string,
		bin: string
	}
}

interface CardForm{
	card_name: string,
	card_number: string,
	expiration: string,
	cvv: string,
	zipcode: string,
}

interface OpenPayResponse{
	status: number,
	data: {
		id: string,
		card: {
			brand: string,
			card_number: string,
			expiration_month: string,
			expiration_year: string,
			holder_name: string,
			type: string,	
		}
	}
}

interface OpenPayError{
	status: number,
	message: string,
	data: {
		description: string,
		error_code: number,
	}
}

var PayfrontForm = React.forwardRef((props: PayfrontFormProps, ref: React.Ref<PayfrontFormElement>)=>{
	var webdata = useWebdata();
	var Payfront = usePayfront();
	var [errorMessage, setErrorMessage] = useState<string[]>(null);
	var [cardForm, setCardForm] = useState<CardForm>({
		card_name: '',
		card_number: '',
		expiration: '',
		cvv: '',
		zipcode: '',
	});

	useEffect(() => {
		var op_script = document.createElement('script');
		op_script.src = 'https://js.openpay.mx/openpay.v1.min.js';
		document.body.appendChild(op_script);

		var opd_script = document.createElement('script');
		opd_script.src = 'https://js.openpay.mx/openpay-data.v1.min.js';
		document.body.appendChild(opd_script);

		return ()=>{
			document.body.removeChild(op_script);
			document.body.removeChild(opd_script);
		}
	}, [props.payfront, props.openpay]);

	useImperativeHandle(ref, ()=>({
		getToken: (amount: number) : Promise<TokenResponse>=>{
			// if(!(window as any).Payfront) return Promise.resolve({ error: true, message: 'Hubo un error configuración del procesador de pagos. (LCL-UPF-1)' });
			return new Promise((resolve, reject)=>{
				var { valid, prompts } = Validator(cardForm, {
					card_name: [{ rule: 'minLength', params: [4], prompt: 'El nombre de tarjetahabiente no es válido.' }],
					card_number: [{ rule: 'minLength', params: [15], prompt: 'El número de tarjeta no es válido.' }],
					cvv: [{ rule: 'minLength', params: [3], prompt: 'El código de seguridad no es válido.' }],
					expiration: [{ rule: /[0-1][0-9]\/[0-9]{2}/gi, prompt: 'La fecha de expiración no es válida.' }],
					zipcode: [{ rule: 'minLength', params: [4], prompt: 'El código postal no es válido.' }],
				});
				setErrorMessage(prompts);
				if(!valid){
					return resolve({ error: true, message: prompts[0] });
				}

				var number = cardForm.card_number.replace(/[^0-9]/gi, '')

				var bin = number.toString().substring(0, 6);
				var digits = number.toString().slice(-4);
				var [month, year] = cardForm.expiration.split('/');
				var is_amex = /^3[47][0-9]{13}$/.test(number);

				if(!is_amex && !webdata.variables.force_openpay){
					setErrorMessage(null);
					Payfront.createToken({
						api_key: `${props.payfront.clientId}:${props.payfront.publicKey}`,
						card_name: cardForm.card_name,
						card_number: number,
						expiration: `${month}/${year}`,
						card_cvv: cardForm.cvv,
						amount: amount,
						postal_code: cardForm.zipcode,
						email: props.orderEmail,
					}).then(res=>{
						if(res.error){
							if(res.prompts) setErrorMessage(res.prompts);
							else setErrorMessage([res.message]);
						}
						return resolve({
							error: !!res.error,
							message_internal: res.message,
							message: res.message,
							data: {
								bin: null,
								digits: null,
								type: ProcessorProvider.PAYFRONT,
								token: res.data?.token,
								device: null,
							}
						})
					})
				}else{
					(window as any).OpenPay.setId(props.openpay.merchantId);
					(window as any).OpenPay.setApiKey(props.openpay.apiKey);
					(window as any).OpenPay.setSandboxMode(props.sandbox);
					var deviceSessionId = (window as any).OpenPay.deviceData.setup();

					(window as any).OpenPay.token.create({
						card_number: number,
						holder_name: cardForm.card_name,
						expiration_month: month,
						expiration_year: year,
						cvv2: cardForm.cvv,
					}, (res: OpenPayResponse)=>{
						return resolve({
							error: false,
							data: {
								bin, digits,
								type: ProcessorProvider.OPENPAY,
								device: deviceSessionId,
								token: res.data.id,
							}
						})
					}, (res: OpenPayError)=>{
						setErrorMessage([`Hubo un error realizando el pago. No se ha realizado ningún cargo (LCL-OPER-${res.data.error_code})`]);
						return resolve({
							error: true,
							message_internal: res.data.description,
							message: `Hubo un error realizando el pago. No se ha realizado ningún cargo (LCL-OPER-${res.data.error_code})`,
						})
					});
				}
			});
		}
	}), [props.payfront, props.openpay, cardForm]);

	var formatExpiration = (val: string)=>{
		val = val.replace(/\//g, '');
		if(val.length>=3){
			return val.replace(/\//g, '').substring(0, 2) + '/' + val.substring(2);
		}
		return val.replace(/\//g, '');
	}

	function formatCreditCard(val: string){
		val = val.replace(/\ /g, '');
		var is_amex = /^3[47][0-9]{13}$/.test(val);
		if(is_amex){
			return val.replace(/\b(\d{4})(\d{6})(\d{5})\b/, '$1 $2 $3');
		}else{
			var final_val = '';
			for(var i=0; i<val.length; i++){
				final_val += val[i];
				if(((i+1)%4)==0 && i<(val.length-1)){
					final_val += ' '
				}
			}
			return final_val;
		}
	}

	var onCardFormChange = bindFormChange(cardForm, setCardForm);
	
	return <>
		<Input label='Nombre en tarjeta' value={cardForm.card_name} onChange={onCardFormChange('card_name')} />
		<Input label='Número de tarjeta' value={cardForm.card_number} onChange={onCardFormChange('card_number')} placeholder='Dígitos de la tarjeta' maxLength={16+3} valueFormat={formatCreditCard} />
		<Field amount={2}>
			<Input label='Fecha de expiración' value={cardForm.expiration} onChange={onCardFormChange('expiration')} placeholder='MM/YY' valueFormat={formatExpiration} maxLength={5} />
			<Input label='Código de seguridad' value={cardForm.cvv} onChange={onCardFormChange('cvv')} placeholder='CVV' maxLength={5} />
		</Field>
		<Input label='Código postal' value={cardForm.zipcode} onChange={onCardFormChange('zipcode')} placeholder='Código postal' maxLength={6} />
		{!!errorMessage && (
			<Message type='error' list={errorMessage} />
		)}
	</>
})

export default PayfrontForm;